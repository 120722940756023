import React, { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import TextField from '@mui/material/TextField';
import { supabase } from '../../api/supabase';
import IngredientDialog from './IngredientDialog';
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css';

function Ingredients(props) {
  const { isAdmin } = props.useAuth;
  const [ingredients, setIngredients] = useState(false);
  const [searchResults, setSearchResults] = useState(ingredients);
  const [listHeight, setListHeight] = useState(0);
  const listRef = React.useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (listRef.current) {
        const rect = listRef.current.getBoundingClientRect();
        setListHeight(rect.height);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const getData = async () => {
    const { data: _ingredients, error } = await supabase
      .from('calculation_ingredients')
      .select('*');
    
    if (error) {
      console.error('Error fetching ingredients:', error);
      return;
    }

    _ingredients.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    setIngredients(_ingredients);
    setSearchResults(_ingredients);
  }

  useEffect(() => {
    getData();
  }, []);

  const Row = ({ index, style }) => (
    <div style={style}>
      <IngredientDialog 
        isAdmin={isAdmin} 
        item={searchResults[index]} 
        getData={getData} 
      />
    </div>
  );

  const VirtualizedList = () => (
    <List
      height={listHeight}
      itemCount={searchResults?.length || 0}
      itemSize={70}
      width={600}
    >
      {Row}
    </List>
  );

  return (
    <div className="ingredients-page">
      <div className="ingredients-header">
        <h1>Ingredienser</h1>
        <p>Hantera ingredienser och priser</p>
        <a href="/kalkyler" className="nav-link">
          Gå till Kalkyler →
        </a>
      </div>
      
      <div className="ingredients-controls">
        {ingredients && <SearchBox arr={ingredients} setArray={setSearchResults} />}
        <IngredientDialog isAdmin={isAdmin} getData={getData} isCreate={true} />
      </div>

      <div className="IngredientList__list" ref={listRef}>
        {listHeight > 0 && <VirtualizedList />}
      </div>
    </div>
  );
}

function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = e => {
    setSearchTerm(e.target.value);
    const results = arr.filter(item => 
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setArray(results);
  };

  return (
    <TextField
      id="standard-basic"
      label="Sök Ingrediens"
      variant="outlined"
      type="search"
      value={searchTerm}
      onChange={handleChange}
      size="small"
      autoComplete="off"
    />
  );
}

export default WithUseAuth(Ingredients);
