import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { supabase } from "../../api/supabase";
import './styles.css';

function IngredientDialog({ item, isAdmin, getData, isCreate = false }) {
  const [open, setOpen] = useState(false);
  const [ingredient, setIngredient] = useState(
    isCreate ? { name: '', price_per_kg: '' } : item
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIngredient(isCreate ? { name: '', price_per_kg: '' } : item);
  };

  const handleSave = async () => {
    // Validate required fields
    if (!ingredient.name.trim() || !ingredient.price_per_kg.trim()) {
      alert('Alla fält måste fyllas i');
      return;
    }

    let error;
    
    if (isCreate) {
      const { error: createError } = await supabase
        .from('calculation_ingredients')
        .insert([{
          name: ingredient.name.trim(),
          price_per_kg: ingredient.price_per_kg
        }]);
      error = createError;
    } else {
      const { error: updateError } = await supabase
        .from('calculation_ingredients')
        .update({
          name: ingredient.name.trim(),
          price_per_kg: ingredient.price_per_kg
        })
        .eq('id', ingredient.id);
      error = updateError;
    }

    if (error) {
      console.error('Error saving ingredient:', error.message, error.details, error.hint);
      alert(`Kunde inte ${isCreate ? 'skapa' : 'uppdatera'} ingrediens: ${error.message}`);
      return;
    }

    getData();
    handleClose();
  };

  const handleDelete = async () => {
    if (!window.confirm('Är du säker på att du vill ta bort denna ingrediens?')) {
      return;
    }

    const { error } = await supabase
      .from('calculation_ingredients')
      .delete()
      .eq('id', ingredient.id);

    if (error) {
      console.error('Error deleting ingredient:', error.message, error.details, error.hint);
      alert(`Kunde inte ta bort ingrediens: ${error.message}`);
      return;
    }

    getData();
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'price_per_kg') {
      // Only allow valid price format: numbers with exactly two decimals after a dot
      const regex = /^\d*\.?\d{0,2}$/;
      if (!regex.test(value)) return;
    }
    setIngredient(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (isCreate && !isAdmin) return null;

  return (
    <>
      {isCreate ? (
        <Button 
          color="primary" 
          variant="contained" 
          onClick={handleClickOpen}
          size="small"
        >
          Lägg till +
        </Button>
      ) : (
        <div onClick={handleClickOpen} className="list-item hover-pointer">
          <div className="list-item-content">
            <div className="list-item-title">{item.name}</div>
            <div className="list-item-subtitle">{item.price_per_kg}kr/kg</div>
          </div>
        </div>
      )}
      <Dialog 
        open={open} 
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        classes={{
          paper: 'ingredient-dialog'
        }}
      >
        <DialogTitle className="ingredient-dialog-title">
          {isCreate ? 'Lägg till ingrediens' : 'Redigera ingrediens'}
        </DialogTitle>
        <DialogContent className="ingredient-dialog-content">
          <TextField
            autoFocus={isCreate}
            label="Namn"
            type="text"
            fullWidth
            name="name"
            value={ingredient.name}
            onChange={handleChange}
            disabled={!isAdmin}
            variant="outlined"
            size="small"
            required
            helperText={!ingredient.name.trim() ? 'Namn krävs' : ' '}
          />
          <div className="spacer-10" />
          <TextField
            label="Pris per kg"
            type="text"
            fullWidth
            name="price_per_kg"
            value={ingredient.price_per_kg}
            onChange={handleChange}
            disabled={!isAdmin}
            variant="outlined"
            size="small"
            required
            helperText='Ange pris med max två decimaler (t.ex. 123.45)'
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*\\.?[0-9]{0,2}'
            }}
          />
        </DialogContent>
        <DialogActions className="ingredient-dialog-actions">
          <div>
            <Button onClick={handleClose} color="inherit" size="small">
              Avbryt
            </Button>
            {!isCreate && isAdmin && (
              <Button onClick={handleDelete} color="secondary" size="small">
                Ta bort
              </Button>
            )}
          </div>
          {isAdmin && (
            <Button 
              onClick={handleSave} 
              color="primary" 
              variant="contained" 
              size="small"
              disabled={!ingredient.name.trim() || !ingredient.price_per_kg.trim()}
            >
              {isCreate ? 'Skapa' : 'Spara'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default IngredientDialog; 