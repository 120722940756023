import React, { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import TextField from '@mui/material/TextField';
import { supabase } from '../../api/supabase';
import CalculationDialog from './CalculationDialog';
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css';

function Calculations(props) {
  const { isAdmin } = props.useAuth;
  const [calculations, setCalculations] = useState(false);
  const [searchResults, setSearchResults] = useState(calculations);
  const [listHeight, setListHeight] = useState(0);
  const listRef = React.useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (listRef.current) {
        const rect = listRef.current.getBoundingClientRect();
        setListHeight(rect.height);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const getData = async () => {
    const { data: _calculations, error } = await supabase
      .from('calculation')
      .select('*')
      .order('name', { ascending: true });
    
    if (error) {
      console.error('Error fetching calculations:', error);
      return;
    }

    setCalculations(_calculations);
    setSearchResults(_calculations);
  }

  useEffect(() => {
    getData();
  }, []);

  const Row = ({ index, style }) => (
    <div style={style}>
      <CalculationDialog 
        isAdmin={isAdmin} 
        item={searchResults[index]} 
        getData={getData} 
      />
    </div>
  );

  const VirtualizedList = () => (
    <List
      height={listHeight}
      itemCount={searchResults?.length || 0}
      itemSize={70}
      width={600}
    >
      {Row}
    </List>
  );

  return (
    <div className="ingredients-page">
      <div className="ingredients-header">
        <h1>Kalkyler</h1>
        <p>Hantera kalkyler och recept</p>
        <a href="/ingredienser" className="nav-link">
          Gå till Ingredienser →
        </a>
      </div>
      
      <div className="ingredients-controls">
        {calculations && <SearchBox arr={calculations} setArray={setSearchResults} />}
        <CalculationDialog isAdmin={isAdmin} getData={getData} isCreate={true} />
      </div>

      <div className="IngredientList__list" ref={listRef}>
        {listHeight > 0 && <VirtualizedList />}
      </div>
    </div>
  );
}

function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = e => {
    setSearchTerm(e.target.value);
    const results = arr.filter(item => 
      item.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.ingredient?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setArray(results);
  };

  return (
    <TextField
      id="standard-basic"
      label="Sök Kalkyl"
      variant="outlined"
      type="search"
      value={searchTerm}
      onChange={handleChange}
      size="small"
      autoComplete="off"
    />
  );
}

export default WithUseAuth(Calculations);

