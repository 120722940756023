import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

import { AuthProvider } from './pages/auth/AuthContext'
import { Login } from './pages/auth/Login'
import { PrivateRoute } from './pages/auth/PrivateRoute'
import Nav from './pages/components/Nav/Nav';
import OrderSedel from './pages/order/OrderSedel'
import Pralinrum from './Pages/Pralinrum/Sorter'
import PlannerList from './pages/planner/PlannerList';
import ReceptList from './pages/recept/ReceptList';
import Whiteboard from './pages/whiteboard/Whiteboard'
import PersonsList from './pages/persons/PersonsList'
import FrysList from './pages/frys/FrysList'
import Tracking from './pages/tracking/Tracking'
import Test from './pages/Test'
import Labs from './pages/Labs'
import ButiksLager from './pages/butik/ButiksLager'
import FortnoxList from './pages/FortnoxList';

// v2
import './v2/ui/aui.css'
import './v2/ui/styles.css'
import CreateAbsence from './v2/pages/absence/Create'
import Dashboard from './v2/pages/dashboard/Dashboard'
import Freezer from './v2/pages/freezer/Freezer'
import Tracking2 from './v2/pages/tracking/Tracking2'
import Ingredients from './pages/prices/Ingredients'
import Calculations from './pages/prices/Calculations'

function App() {

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Nav />
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/" component={OrderSedel} />
            <PrivateRoute exact path="/pralinrum" component={Pralinrum} />
            <PrivateRoute exact path="/planner" component={PlannerList} />
            <PrivateRoute exact path="/recept" component={ReceptList} />
            <PrivateRoute exact path="/personal" component={PersonsList} />
            <PrivateRoute exact path="/frys" component={Freezer} />
            <PrivateRoute exact path="/tracking" component={Tracking} />
            {/* <PrivateRoute exact path="/tracking2" component={Tracking2} /> */}
            <PrivateRoute exact path="/fortknox" component={FortnoxList} />
            <PrivateRoute exact path="/labs" component={Labs} />
            <PrivateRoute exact path="/butikslager" component={ButiksLager} />
            <PrivateRoute exact path="/ingredienser" component={Ingredients} />
            <PrivateRoute exact path="/kalkyler" component={Calculations} />

            <Route path="/login" component={Login} />
            <Route exact path="/anmal_franvaro" component={CreateAbsence} />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App