import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import { supabase } from "../../api/supabase";
import './styles.css';

function CalculationDialog({ item, isAdmin, getData, isCreate = false }) {
  const [open, setOpen] = useState(false);
  const [calculation, setCalculation] = useState(
    isCreate ? { 
      name: '', 
      ingredients: [], 
      amounts: [] 
    } : {
      ...item,
      ingredients: item?.ingredients || [],
      amounts: item?.amounts || []
    }
  );
  const [ingredientsList, setIngredientsList] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (open) {
      fetchIngredients();
      initializeRows();
    }
  }, [open]);

  const fetchIngredients = async () => {
    const { data, error } = await supabase
      .from('calculation_ingredients')
      .select('*')
      .order('name');
    
    if (error) {
      console.error('Error fetching ingredients:', error);
      return;
    }

    setIngredientsList(data);
  };

  const initializeRows = () => {
    if (calculation.ingredients && calculation.amounts) {
      const newRows = calculation.ingredients.map((ingredient, index) => ({
        ingredient: ingredient || '',
        amount: calculation.amounts[index] || '',
        price_per_kg: ''
      }));
      setRows(newRows.length > 0 ? newRows : [{ ingredient: '', amount: '', price_per_kg: '' }]);
      // Fetch prices for existing ingredients
      newRows.forEach((row, index) => {
        if (row.ingredient) {
          updateIngredientPrice(row.ingredient, index);
        }
      });
    } else {
      setRows([{ ingredient: '', amount: '', price_per_kg: '' }]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCalculation(isCreate ? { 
      name: '', 
      ingredients: [], 
      amounts: [] 
    } : {
      ...item,
      ingredients: item?.ingredients || [],
      amounts: item?.amounts || []
    });
    setRows([]);
  };

  const handleSave = async () => {
    if (!calculation.name?.trim()) {
      alert('Namn måste fyllas i');
      return;
    }

    const ingredients = rows.map(row => row.ingredient).filter(Boolean);
    const amounts = rows.map(row => row.amount).filter(Boolean);

    let error;
    
    if (isCreate) {
      const { error: createError } = await supabase
        .from('calculation')
        .insert([{
          name: calculation.name.trim(),
          ingredients,
          amounts,
          updated_at: new Date().toISOString()
        }]);
      error = createError;
    } else {
      const { error: updateError } = await supabase
        .from('calculation')
        .update({
          name: calculation.name.trim(),
          ingredients,
          amounts,
          updated_at: new Date().toISOString()
        })
        .eq('id', calculation.id);
      error = updateError;
    }

    if (error) {
      console.error('Error saving calculation:', error.message, error.details, error.hint);
      alert(`Kunde inte ${isCreate ? 'skapa' : 'uppdatera'} kalkyl: ${error.message}`);
      return;
    }

    getData();
    handleClose();
  };

  const handleDelete = async () => {
    if (!window.confirm('Är du säker på att du vill ta bort denna kalkyl?')) {
      return;
    }

    const { error } = await supabase
      .from('calculation')
      .delete()
      .eq('id', calculation.id);

    if (error) {
      console.error('Error deleting calculation:', error.message, error.details, error.hint);
      alert(`Kunde inte ta bort kalkyl: ${error.message}`);
      return;
    }

    getData();
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCalculation(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const updateIngredientPrice = async (ingredientName, rowIndex) => {
    const ingredient = ingredientsList.find(i => i.name === ingredientName);
    if (ingredient) {
      setRows(prev => prev.map((row, index) => 
        index === rowIndex ? { ...row, price_per_kg: ingredient.price_per_kg } : row
      ));
    }
  };

  const handleRowChange = (index, field, value) => {
    setRows(prev => prev.map((row, i) => {
      if (i === index) {
        if (field === 'ingredient') {
          const selectedIngredient = ingredientsList.find(ing => ing.name === value);
          return {
            ...row,
            ingredient: value,
            price_per_kg: selectedIngredient ? selectedIngredient.price_per_kg : ''
          };
        }
        return { ...row, [field]: value };
      }
      return row;
    }));
  };

  const addRow = () => {
    setRows(prev => [...prev, { ingredient: '', amount: '', price_per_kg: '' }]);
  };

  const removeRow = (index) => {
    setRows(prev => prev.filter((_, i) => i !== index));
  };

  const calculatePrice = (amount, pricePerKg) => {
    if (!amount || !pricePerKg) return 0;
    const grams = parseFloat(amount);
    const price = parseFloat(pricePerKg);
    if (isNaN(grams) || isNaN(price)) return 0;
    return (grams / 1000) * price;
  };

  const calculateTotalPrice = () => {
    return rows.reduce((total, row) => {
      return total + calculatePrice(row.amount, row.price_per_kg);
    }, 0);
  };

  const calculateTotalGrams = () => {
    return rows.reduce((total, row) => {
      const amount = parseFloat(row.amount) || 0;
      return total + amount;
    }, 0);
  };

  const calculateStrutar = () => {
    const totalGrams = calculateTotalGrams();
    return Math.floor(totalGrams / 150);
  };

  const calculatePricePerStrut = () => {
    const totalPrice = calculateTotalPrice();
    const strutar = calculateStrutar();
    return strutar > 0 ? totalPrice / strutar : 0;
  };

  if (isCreate && !isAdmin) return null;

  return (
    <>
      {isCreate ? (
        <Button 
          color="primary" 
          variant="contained" 
          onClick={handleClickOpen}
          size="small"
        >
          Lägg till +
        </Button>
      ) : (
        <div onClick={handleClickOpen} className="list-item hover-pointer">
          <div className="list-item-content">
            <div className="list-item-title">{item.name}</div>
            <div className="list-item-subtitle">
              {item.ingredients?.length} ingredienser
            </div>
          </div>
        </div>
      )}
      <Dialog 
        open={open} 
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        classes={{
          paper: 'ingredient-dialog'
        }}
      >
        <DialogTitle className="ingredient-dialog-title">
          <div className="dialog-title-content">
            <div>{isCreate ? 'Lägg till kalkyl' : calculation.name}</div>
            {!isCreate && (
              <Button
                onClick={() => window.print()}
                color="inherit"
                size="small"
                startIcon={<PrintIcon />}
              >
                Skriv ut
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent className="ingredient-dialog-content">
          <TextField
            autoFocus={isCreate}
            label="Namn"
            type="text"
            fullWidth
            name="name"
            value={calculation.name || ''}
            onChange={handleChange}
            disabled={!isAdmin}
            variant="outlined"
            size="small"
            required
            helperText={!calculation.name?.trim() ? 'Namn krävs' : ' '}
          />
          <div className="spacer-5" />
          
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Ingrediens</TableCell>
                  <TableCell>Mängd (g)</TableCell>
                  <TableCell>Pris per kg</TableCell>
                  <TableCell>Pris (kr)</TableCell>
                  <TableCell width={50}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Select
                        value={row.ingredient}
                        onChange={(e) => handleRowChange(index, 'ingredient', e.target.value)}
                        disabled={!isAdmin}
                        fullWidth
                        size="small"
                      >
                        <MenuItem value="">Välj ingrediens</MenuItem>
                        {ingredientsList.map(ingredient => (
                          <MenuItem key={ingredient.id} value={ingredient.name}>
                            {ingredient.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={row.amount}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Only allow whole numbers
                          if (value.includes('.') || value.includes(',')) return;
                          handleRowChange(index, 'amount', value);
                        }}
                        disabled={!isAdmin}
                        size="small"
                        fullWidth
                        inputProps={{
                          step: "1",
                          min: "0"
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {row.price_per_kg} kr/kg
                    </TableCell>
                    <TableCell>
                      {calculatePrice(row.amount, row.price_per_kg).toFixed(2)} kr
                    </TableCell>
                    <TableCell>
                      {isAdmin && (
                        <IconButton size="small" onClick={() => removeRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {isAdmin && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={addRow}
                        size="small"
                      >
                        Lägg till ingrediens
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {rows.length > 0 && (
            <div className="calculation-summary">
              <div data-label="Totalt:">
                {calculateTotalPrice().toFixed(2)} kr
              </div>
              <div data-label="Antal strutar:">
                {calculateStrutar()} st ({(calculateTotalGrams() / 150).toFixed(2)})
              </div>
              <div data-label="Pris per strut:">
                {calculatePricePerStrut().toFixed(2)} kr
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className="ingredient-dialog-actions">
          <div className="flex-left">
            <Button onClick={handleClose} color="inherit" size="small">
              Avbryt
            </Button>
            {!isCreate && isAdmin && (
              <Button onClick={handleDelete} color="secondary" size="small">
                Ta bort
              </Button>
            )}
          </div>
          {isAdmin && (
            <Button 
              onClick={handleSave} 
              color="primary" 
              variant="contained" 
              size="small"
              disabled={!calculation.name?.trim()}
            >
              {isCreate ? 'Skapa' : 'Spara'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CalculationDialog; 